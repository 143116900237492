<template>
  <div class="home">
    <Section light>
      <v-col cols="12">
        <div>
          <h1>Our Members</h1>
        </div>
        <div class="image-container">
          <img src="@/assets/members/YonderGroup.jpg" />
        </div>
      </v-col>
      <v-col cols="12">
        <div>
          <h1>PMs</h1>
        </div>
      </v-col>
      <Member
        v-for="member in pm"
        :key="member[0]"
        :name="member[0]"
        :picture="member[1]"
      />
      <v-col cols="12">
        <div>
          <h1>Electrical</h1>
        </div>
      </v-col>
      <Member
        v-for="member in electrical"
        :key="member[0]"
        :name="member[0]"
        :picture="member[1]"
      />
      <v-col cols="12">
        <div>
          <h1>Mechanical</h1>
        </div>
      </v-col>
      <Member
        v-for="member in mechanical"
        :key="member[0]"
        :name="member[0]"
        :picture="member[1]"
      />
      <v-col cols="12">
        <div>
          <h1>Science</h1>
        </div>
      </v-col>
      <Member
        v-for="member in science"
        :key="member[0]"
        :name="member[0]"
        :picture="member[1]"
      />
      <v-col cols="12">
        <div>
          <h1>Software</h1>
        </div>
      </v-col>
      <Member
        v-for="member in software"
        :key="member[0]"
        :name="member[0]"
        :picture="member[1]"
      />
    </Section>
  </div>
</template>

<script>
import Section from '@/components/Section.vue';
import Member from '@/components/Member.vue';

export default {
  name: 'Mechanical',
  components: {
    Section,
    Member,
  },
  created() {
    this.pm = [
      ['Claire Wang', 'claire.jpg'],
      ['Charlie Lahey', 'charlie.jpg'],
    ];
    this.electrical = [
      ['Anton John Del Mar', 'anton.jpg'],
      ['Bradley Chen', 'bradley.jpg'],
      ['Brendon Chang ', 'brendon.jpg'],
      ['Daniel Weng', 'daniel.jpg'],
      ['Elizabeth Dam', 'elizabeth.jpg'],
      ['Lucas Wang', 'lucas.jpg'],
      ['Ned Bitar', 'ned.jpg'],
      ['Robin Wang', 'robin.jpg'],
      ['Rylee Correa', 'rylee.jpg'],
      ['Stanley Pan', 'stanley.jpg'],
      ['Vicky Tang', 'vicky.jpg'],
      ['Zoey Evans', 'zoey.jpg'],
    ];
    this.mechanical = [
      ['Alex Burns', 'alex.jpg'],
      ['Anna Li', 'anna.jpg'],
      ['Astra Tulac', 'astra.jpg'],
      ['Dylan Lee', 'dylan.jpg'],
      ['Harsh Savla', 'harsh.jpg'],
      ['Justin Chen', 'justin.jpg'],
      ['Kayson Neduvakkattu', 'kayson.jpg'],
      ['Marfred Barrera', 'marfred.jpg'],
      ['Najmi Hanis', 'najmi.jpg'],
      ['Rachel Hartanto', 'rachel.jpg'],
      ['Sheen Shaji', 'sheen.jpg'],
      ['Sujaan Mukherjee', 'sujaan.jpg'],
      ['Vera Wong', 'vera.jpg'],
    ];
    this.science = [
      ["Breila O'Malley", 'breila.jpg'],
      ['Daniel Zhang', 'daniel-sci.jpg'],
      ['Kensuke Shimojo', 'kensuke.jpg'],
      ['Ly Nguyen', 'ly.jpg'],
      ['Pelin Kaynak', 'pelin.jpg'],
    ];
    this.software = [
      ['Aaryan Agrawal', 'aaryan.jpg'],
      ['Andrea Calderon', 'andrea.jpg'],
      ['Arjun Suresh Kumar', 'arjun.jpg'],
      ['Kobe Yang', 'kobe.jpg'],
      ['Lulu Shao', 'lulu.jpg'],
      ['Misha Bokyo', 'misha.jpg'],
      ['Pranav Rebala', 'pranav.jpg'],
      ['Prash Katukojwala', 'prash.jpg'],
      ['Prem Netsuwan', 'prem.jpg'],
      ['Ryan Younan', 'ryan.jpg'],
      ['Sierra Myers', 'sierra.jpg'],
    ];
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
}

.image-container img {
  display: block;
  width: 80%;
  margin: auto;
  padding-top: 20px;
}
</style>

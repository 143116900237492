<template>
  <v-col class="col-md-3 col-sm-6 col-12">
    <v-card>
      <v-img
        :src="require(`@/assets/members/${this.picture}`)"
        class="white--text align-end pa-4"
        gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,0) 60%, rgba(0,0,0,.5) 80%"
      >
        <h2>
          {{ name }}
        </h2>
      </v-img>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: 'Member',
  props: ['name', 'picture'],
};
</script>

<style scoped>
</style>
